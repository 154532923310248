<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <div>
          <b-card-title>{{ `รายชื่อตัวแทนในบิล ${total} รายการ` }}</b-card-title>
        </div>
      </b-card-header>
      <hr class="m-0">
      <!-- Table -->
      <b-table
        :items="dataList"
        :fields="fields"
        responsive
        show-empty
        hover
      >
        <template #cell(totalFee)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(commissionRate)="data">
          {{ data.value }}%
        </template>
        <template #cell(totalAmount)="data">
          {{ data.value | currency }}
        </template>
        <template #empty="">
          <p class="text-center text-muted">
            ไม่มีรายการ
          </p>
        </template>
      </b-table>
      <!-- pagination -->
      <b-card-footer>
        <b-row class="">
          <b-col md="4">
            <page-limit-select
              :value="limit"
              @update="onLimitChange"
            />
          </b-col>
          <b-col>
            <pagination-input
              :per-page="limit"
              :total="total"
              @update="(val) => (currentPage = val)"
            />
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      search: '',
      onSearchTimeout: null,
      fields: [
        {
          key: 'agentName',
          label: 'ชื่อตัวแทน',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'totalFee',
          label: 'ค่าธรรมเนียมในรอบ',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'commissionRate',
          label: '% ที่เรียกเก็บ',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'totalAmount',
          label: 'ยอดที่เรียกเก็บ',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'id',
          label: '',
          thStyle: {
            minWidth: '200px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['agents']),
    dataList() {
      return []
    },
    pagination() {
      return {
        itemsPerPage: 0,
        totalItems: 0,
      }
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: {
    currentPage(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions([]),
    fetchData() {},
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onLimitChange(limit) {
      if (limit) {
        this.selectedLimit = limit
        this.fetchData()
      }
    },
  },
}
</script>
